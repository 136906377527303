import React from "react";
import ButtonOutlined from "../components/ButtonOutlined";
import Layout from "../components/Layout";
import SideBar from "../components/SideBar";
import SEO from "../components/SEO";

const Index = () => {
  return (
    <Layout>
      <SEO title="404 - Página não encontrada" />
      <div class="flex flex-col lg:flex-row h-screen">
        <div class="w-full lg:w-8/12">
          <h1 class="mt-2 text-4xl font-semibold text-center text-white">
            Página não encontrada
          </h1>
          <h2 class="h-24 mt-24 text-lg font-semibold text-center text-white">
            Construir Sozinho ainda não construiu a página que procura.
            Construir tudo sozinho é difícil.
          </h2>
          <div class="mt-2 flex justify-center">
            <ButtonOutlined text="VOLTAR" to="/" />
          </div>
        </div>
        <div class="w-full lg:w-4/12 mt-2 lg:pl-2 text-white">
          <SideBar />
        </div>
      </div>
    </Layout>
  );
};

export default Index;
